<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
// import Swal from "sweetalert2"
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Edit User",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            session: JSON.parse(localStorage.session),
            title: "Profile User",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "User",
                    href: "/master/user",
                },
                {
                    text: "Profile User",
                    active: true,
                },
            ],
            axiosCatchError: null,
            // variable Page
            idUser: this.$route.params.idUser,
            master_role: [],
            master_department: [],
            master_division: [],

            cwid: null,
            name: null,
            phone: null,
            email: null,
            role_selected: [],
            department_selected: [],
            division_selected: [],
            password: null,
            password_konfirmasi: null,
            showPassword: false,
            showPasswordConfirm: false,
        };
    },
    mounted() {
        let self = this;
        Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        // Get Data Detail
        axios
            .get(process.env.VUE_APP_BACKEND_URL_VERSION + "master/user", {
                params: {
                    id: this.session.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            })
            .then((response) => {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if (response_data.meta.code == 200) {
                    var data_edit = response_data_fix.data[0];
                    self.cwid = data_edit.nip;
                    self.name = data_edit.name;
                    self.phone = data_edit.phone;
                    self.email = data_edit.email;
                    // self.role_selected = data_edit.role;
                    // self.division_selected = data_edit.division;
                    // self.department_selected = data_edit.department;
                    Swal.close();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
            });
    },
    methods: {
        // Method dari masing masing fungsi di dalam file ini

        getRole(){
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-role",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then((response) => {
                    this.master_role = response.data.data.referensi;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    console.log(this.axiosCatchError);
                    Swal.close();
                });


        },
        getDivision(){
            var configDivision = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-division",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(configDivision)
                .then((response) => {
                    this.master_division = response.data.data.referensi;
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    console.log(this.axiosCatchError);
                    Swal.close();
                });
        },
        getDepartemen(){
            // let self = this;
            var configDepartment = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-department",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(configDepartment)
            .then((response) => {
                this.master_department = response.data.data.referensi;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });
        },

        StoreData() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .put(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "master/user/profile",
                    {
                        id: self.session.id,
                        cwid: self.cwid,
                        name: self.name,
                        email: self.email,
                        division_id: self.division_selected?.mdiv_id,
                        department_id: self.department_selected?.mdep_id,
                        password: self.password,
                        password_konfirmasi: self.password_konfirmasi,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    console.log(response);
                    let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Success update data.",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "profile-user" });
                        }
                    });
                })
                .catch((e) => {
                    this.axiosCatchError = e.response.data.data;
                    Swal.close();
                });
        },
        cekcwid() {
            alert(123);
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleShowConfirm() {
            this.showPasswordConfirm = !this.showPasswordConfirm;
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <b-form class="p-2" @submit.prevent="StoreData">
                        <div class="card-body">
                            <div v-if="axiosCatchError">
                                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                                    {{ axiosCatchError }}
                                </div>
                                <div class="alert alert-danger" role="alert" v-else>
                                    <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                                        <div v-for="(allErrors, idx) in errorArray" :key="idx">
                                            <span class="text-danger">{{ allErrors[0] }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3">
                                        <label for="formrow-cwid">CWID <span class="text-danger">*</span></label>
                                        <b-form-input id="formrow-cwid" type="text" v-model="cwid"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="formrow-name-input">Nama <span class="text-danger">*</span></label>
                                        <b-form-input id="formrow-name-input" type="text" v-model="name"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="formrow-email-input">Email <span class="text-danger">*</span></label>
                                        <b-form-input id="formrow-email-input" type="text" v-model="email"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="formrow-password-input">Password <span class="text-danger">*</span></label>
                                        <b-input-group prepend="">
                                            <b-form-input v-if="showPassword" id="formrow-password-input" type="text" v-model="password"></b-form-input>
                                            <b-form-input v-else id="formrow-password-input" type="password" v-model="password"></b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="toggleShow">
                                                    <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group class="mb-3">
                                        <label for="formrow-password-input">Confirm Password <span class="text-danger">*</span></label>
                                        <b-input-group prepend="">
                                            <b-form-input v-if="showPasswordConfirm" id="formrow-password-confirm-input" type="text" v-model="password_konfirmasi"></b-form-input>
                                            <b-form-input v-else id="formrow-password-confirm-input" type="password" v-model="password_konfirmasi"></b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="toggleShowConfirm">
                                                    <i class="fas" :class="{ 'fa-eye-slash': showPasswordConfirm, 'fa-eye': !showPasswordConfirm }"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-end">
                                <b-button variant="primary" type="submit"><i class="fa fa-save"></i> Save</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
